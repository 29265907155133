import React from 'react';
import styles from './ComplianceOfficer.module.css'; // Если используешь модули CSS
import Image1 from './../../assets/5124556.jpg';
import Image2 from './../../assets/4443391.jpg';

const ComplianceOfficer = () => {
    return (
        <div className={styles.complianceContainer}>
            {/* Раздел о горячей линии */}
            <div className={styles.hotlineSection}>
            <h2 className={styles.heading}>Горячая линия АО «Автобусный парк №1»</h2>
            <div className={styles.hotlineContent}>
                <div className={styles.textContent}>
                    <p>
                        АО «Автобусный парк №1» (далее – Общество) уделяет особое внимание развитию и поддержанию 
                        корпоративной культуры, демонстрирующей приверженность принципам добросовестности, прозрачности и честности.
                    </p>
                    <p>
                        В целях дальнейшего сохранения положительного имиджа АО «Автобусный парк №1» сообщает о запуске Горячей линии 
                        для своевременного выявления и предотвращения нарушений законодательства и/или Кодекса поведения, в том числе 
                        случаев мошенничества, коррупции, дискриминации, неэтического поведения и иных нарушений, относящихся к деятельности Общества.
                    </p>
                </div>
            </div>
            <div className={styles.principles}>
                <h3>ГОРЯЧАЯ ЛИНИЯ ОБСЛУЖИВАЕТСЯ НЕЗАВИСИМЫМ ОПЕРАТОРОМ, НА СЛЕДУЮЩИХ ПРИНЦИПАХ:</h3>
                    <div className={styles.textWithImage}>
                        <ul>
                            <li>Вы можете сообщить о любых нарушениях Кодекса поведения, в том числе о фактах коррупции, дискриминации, неэтичного поведения и иных нарушениях.</li>
                        </ul>
                        <img src={Image1} alt="Принципы горячей линии" className={styles.imageSmall} />
                    </div>
                </div>
                
                <div className={styles.guarantees}>
                    <h3>МЫ ГАРАНТИРУЕМ:</h3>
                    <div className={styles.textWithImage}>
                        <ul>
                            <li>Конфиденциальности и анонимности (по желанию обратившегося лица).</li>
                            <li>Регистрации и обработки 100% обращений с последующим предоставлением ответственным лицам Общества.</li>
                        </ul>
                        <img src={Image2} alt="Гарантии" className={styles.imageSmall} />
                    </div>
                </div>


                {/* Блок с контактными данными */}
                <div className={styles.contactBlock}>
                    <h3>ОБРАЩЕНИЯ МОГУТ ПРЕДОСТАВЛЯТЬСЯ ВСЕМИ ЛИЦАМИ НА РУССКОМ, КАЗАХСКОМ И АНГЛИЙСКОМ ЯЗЫКАХ ПОСРЕДСТВОМ СЛЕДУЮЩИХ КАНАЛОВ СВЯЗИ:</h3>
                    <ul>
                        <li>По телефонному номеру: <strong>87171 644 082 (вн. 141)</strong></li>
                        <li>На электронную почту: <a href="mailto:d.temirtasovich@avtopark1.kz" className={styles.emailLink}>d.temirtasovich@avtopark1.kz</a></li>
                        <li>Через моб. номер: <strong>8 777 772 22 22</strong></li>
                    </ul>
                </div>
                <p>
                    Все права защищены. Подробнее читайте на официальном сайте: 
                    <a href="https://www.avtopark1.kz/company" target="_blank" rel="noopener noreferrer"> https://www.avtopark1.kz/company</a>
                </p>
            </div>

            {/* Старый раздел о комплаенс-офицере */}
            <div className={styles.complianceSection}>
                <h2 className={styles.heading}>Комплаенс-офицер</h2>
                <p><strong>Калимуллин Дулат Темиртасович</strong></p>
                <p>тел. 8 7172 644 082 (вн. 141)</p>
                <p>моб. 8 777 772 22 22</p>
                <p>эл.адрес: <a href="mailto:d.temirtasovich@avtopark1.kz">d.temirtasovich@avtopark1.kz</a></p>

                <h3 className={styles.subheading}>Цель деятельности Комплаенс-офицера</h3>
                <p>
                    Обеспечение соблюдения законодательства о противодействии коррупции, мониторинг за реализацией мероприятий 
                    по противодействию коррупции, формирование внутренней правовой и корпоративной культуры Общества, отвергающей 
                    коррупцию и обеспечивающей принципы честности и неподкупности при исполнении своих должностных обязанностей.
                </p>

                <h3 className={styles.subheading}>Задачи</h3>
                <ul>
                    <li className={styles.listItem}>Проведение единой политики в области противодействия коррупции;</li>
                    <li className={styles.listItem}>Формирование у должностных лиц и работников понимания нулевой терпимости к коррупции;</li>
                    <li className={styles.listItem}>Минимизация риска вовлечения работников и третьих лиц в коррупционную деятельность;</li>
                    <li className={styles.listItem}>Разработка и внедрение антикоррупционных стандартов;</li>
                    <li className={styles.listItem}>Воспитание строгого соблюдения антикоррупционного законодательства;</li>
                    <li className={styles.listItem}>Недопущение коррупциогенных факторов в нормативных документах;</li>
                    <li className={styles.listItem}>Обеспечение открытости и объективности при выполнении работ.</li>
                </ul>

                <h3 className={styles.subheading}>Меры по предупреждению и противодействию коррупции</h3>
                <p>Направления деятельности, где возможно возникновение коррупционного риска:</p>
                <ol>
                    <li className={styles.listItem}>Подарки и представительские расходы;</li>
                    <li className={styles.listItem}>Привлечение третьих лиц в вопросах противодействия коррупции;</li>
                    <li className={styles.listItem}>Платежи лицам, занимающим государственные должности;</li>
                    <li className={styles.listItem}>Оказание спонсорской и благотворительной помощи;</li>
                    <li className={styles.listItem}>Совершение сделок и проведение госзакупок;</li>
                    <li className={styles.listItem}>Управление персоналом;</li>
                    <li className={styles.listItem}>Финансовая и юридическая экспертиза документов.</li>
                </ol>

                <h3 className={styles.subheading}>Документы:</h3>
                <ul className={styles.list}>
                    <li><a href="/documents/АО Автобусный парк №1 Перечень должностей подверженных коррупционым рискам.PDF" target="_blank">АО Автобусный парк №1 Перечень должностей подверженных коррупционым рискам</a></li>
                    <li><a href="/documents/Положение антикоррупции.docx" download>Положение антикоррупции</a></li>
                    <li><a href="/documents/Правила урегул. конфл.docx" download>Правила урегулирования конфликта</a></li>
                    <li><a href="/documents/Инструкция по противодейстию коррупции.PDF" download>Инструкция по противодейстию коррупции</a></li>
                    <li><a href="/documents/Политика противодействии коррупции.PDF" download>Политика противодействии коррупции</a></li>
                </ul>
            </div>
        </div>
    );
};

export default ComplianceOfficer;
